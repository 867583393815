import React, { createContext, useReducer, useEffect, useState } from "react";
import AppReducer from "./reducer";
import WebService from "../Service";
const initialState = {
  dataUser: {},
  privileges: [],
  token: null,
}
const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    sessionStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    sessionStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};
export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [token, setToken] = useStateWithLocalStorage("token");
  const [dataUser, setDataUser] = useStateWithLocalStorage("dataUser");
  const [service, setService] = useState(new WebService(state.token))
  function hasRight(a1, a2) {
    return (a1.filter(function (n) { return a2.indexOf(n) !== -1; })).length !== 0;
  }
   function login(data) {
    try {
        const {token:tokenNew, ...user}=data
        setToken(tokenNew)
        const serviceInit = new WebService(tokenNew)
        setDataUser(JSON.stringify(user))
        dispatch({
          type: "LOGIN",
          payload: {
            token: token,
            dataUser: { user: user }
          }

        })
        setService(serviceInit)
        return true
      
    }  catch (e) {
    //console.log(e);
    return false
  }
}
async function retrieve() {
  try {

    const getDataUser = await sessionStorage.getItem('dataUser')
    const getToken = await sessionStorage.getItem('token')
    setService(new WebService(getToken))
    console.log("retrieve====>", getDataUser, getToken)
    dispatch({
      type: "RETRIEVE_TOKEN",
      payload: { token: getToken, dataUser: { user: JSON.parse(getDataUser) } },
    });


  } catch (e) {
    //console.log(e);
  }
  // console.log('user token: ', userToken);

}
async function registration(data) {
  try {
    service.presubscription({ ...data, linked_offre: 1 }, resp => {
      //console.log(resp)
      if (resp.code === 200) {
        login({ login: data.telephone, password: data.password }).then(response => {
          if (resp.code !== 200) {
            throw new Error("Failed");
          }

        })

      }

    },
      error => {

        return console.error(error);

      })
  } catch (err) {
    dispatch({
      type: "TRANSACTION_ERROR",
      payload: err,
    });
  }
}

async function logout() {
  sessionStorage.clear()
  dispatch({
    type: "LOGOUT",
    payload: {
      token: '',
      dataUser: {}
    }
  });

}
useEffect(() => {

}, [token])

return (
  <GlobalContext.Provider
    value={{
      ...state,
      login,
      registration,
      retrieve,
      logout,
      hasRight,
      service,
      setDataUser,
      token
    }}
  >
    {children}
  </GlobalContext.Provider>
);
};
