export const posts = [
    {
      id: '1',
      title: 'Projet redressement Tillabery ',
      excerpt:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
      image: 'https://tinyurl.com/4wzh2ph9',
      category: 'Agriculture, développement rural et sécurité alimentaire',
      publishedAt: 'December 29, 2022',
      author: {
        name: 'Samy Tom',
        avatarUrl: 'https://tinyurl.com/2p8h98w8',
      },
    },
    {
      id: '2',
      title: 'Projet d’appui à l’employabilité des jeunes vulnérables ',
      excerpt:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      image: 'https://tinyurl.com/5czjdxj7',
      category: 'Energie et climat',
      publishedAt: 'November 30, 2022',
      author: {
        name: 'Angelina Gates',
        avatarUrl: 'https://tinyurl.com/2p98t7nh',
      },
    },
    {
      id: '3',
      title: 'Programme de renforcement de la résilience et du développement humain ',
      excerpt:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id.',
      image: 'https://tinyurl.com/yh2xkpzm',
      category: 'Education et emploi des jeunes',
      publishedAt: 'October 31, 2022',
      author: {
        name: 'Busola Banks',
        avatarUrl: 'https://tinyurl.com/2p8fy9ym',
      },
    },
    
  ]