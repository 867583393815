import { useState, useEffect, useContext, useMemo } from 'react'
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Tooltip,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  FormErrorMessage,
  PinInput,
  PinInputField,
  HStack,
  Spacer,
  Stack,
  Center,
  Text,
  Link,
  Radio,
  RadioGroup,
  Divider,
  IconButton,
  // PinInput, PinInputField,
  NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, InputRightAddon,
} from '@chakra-ui/react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from '@chakra-ui/react'
import { GlobalContext } from '../Context';
import { LogoSAVert } from '../Header/LogoSA';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { FiPlus } from 'react-icons/fi';
import { UilEllipsisH } from '@iconscout/react-unicons'
import { decrypt, registration } from '../lib';
import { validation } from '../lib';
import { generateKey } from '../lib';
import { encrypt } from '../lib';
import { QRCode } from 'react-qrcode-logo';
import logo from "../../Media/images/logoqr.png"
import { encode, decode } from 'js-base64';
const RegistrationForm = ({ ...props
}) => {
  const { login, retrieve, service } = useContext(GlobalContext)
  const toast = useToast()
  const [isLogin, setIsLogin] = useState(false)
  const [token, setToken] = useState("registration");
  const [response, setResponse] = useState(null);
  const [secret, setSecret] = useState(null);
  const [qrcode, setQrcode] = useState(null);
  const [backend, setBackend] = useState("");
  let navigate = useNavigate();
  const schemaReset = yup.object().shape({
    mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
    // motpasse: yup.string().min(8, "Minimum 8 caractere").required("Le mot de passe est obligatoire"),
    // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const schemaRegistration = yup.object().shape({
    mail: yup.string().required("Le mail est obligatoire"),
    name: yup.string().required("Le nom est obligatoire"),
    backend: yup.string().required("Le nom est obligatoire"),
    // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const schemaCode = yup.object().shape({
    code: yup.string().required("Le code est obligatoire")
    // .matches(/^[0-9]+$/, "Must be only digits")
    //   .min(6, 'Le code doit avoir 6 chiffres')
    //   .max(6, 'Le code doit avoir 6 chiffres'),
    // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const schemaToken = yup.object().shape({
    // mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
    motpasse: yup.string().min(8, "Minimum 8 caractere").required("Le mot de passe est obligatoire"),
    confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
  });
  const onSubmit = async (data) => {
    if (token === "registration") {
      const { backend, ...restData } = data
      setBackend(backend)
      const req = registration(restData)
      setSecret(req?.secret)
      service.create("", "/registration", { data: encode(JSON.stringify(req?.identifiant)) }, (resp => {
        setToken("code")
        setSelectedSchema(schemaCode)
        setResponse(resp?.data)
        toast({
          title: 'Validation du mail',
          description: `Un code de validation vient d'etre envoye a l'adresse mail ${data.mail} .`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

      }), err => {
        console.log(err)
        toast({
          title: 'Validation du mail',
          description: `Une erreur s'est produite. Contacter l'administateur .`,
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })
      })
    }
    else if (token === "code") {

      const req = validation(response, data?.code, secret)
      service.create("", "/validation", { data: encode(JSON.stringify({...req?.data, url: backend})) }, (resp => {
        toast({
          title: 'Validation du code',
          description: `${resp?.data?.message}`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        const storeResponse = {
          response: response,
          secret: req?.secret
        }
        setToken("validation")
        setResponse(storeResponse)

      }), err => {
        toast({
          title: 'Validation du code',
          description: `Votre code est invalide`,
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })

      })

    } 
    // else if (token === "validation") {
    //   const key = generateKey(data?.motpasse)
    //   const encryptData = encrypt(JSON.stringify(response?.public_key_cert), key)
    //   setQrcode(encryptData)
    //   setToken("qrcode")
    // }
    // else {
    // }
    // const resp = login(data)
    // if (resp) {
    //   setIsLogin(!isLogin)
    //   navigate('/')

    // }


  }
  const [selectedSchema, setSelectedSchema] = useState(schemaReset);
  const { register, handleSubmit, control, watch, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(selectedSchema)
  });

  useEffect(() => {
    if (isLogin) {
      retrieve()
    }

  }, [isLogin])
  useEffect(() => {

    if (token === "code") {

      setSelectedSchema(schemaCode)
    } else if (token === "registration") {
      setSelectedSchema(schemaRegistration)
    } else if (token === "password") {
      setSelectedSchema(schemaToken)
    }

  }, [token])


  return (

    <Stack

      spacing="2" {...props} w={{ base: "full", md: 'xl' }} as="section"
      borderColor="brand.primary.100"
      shadow='lg'
      // borderRadius={"lg"}
      // px={5}
      borderBottomWidth={4}
      borderBottomColor={"brand.primary.100"}
      minH="400px"

    >
      <Center>


        <LogoSAVert
          title="Bienvenu sur Naibi SSO"
        />

      </Center>
      <Stack spacing="5" fontSize={"2xl"} px={10}>
        <form
        >

          {"registration" === token &&
            <>
              <FormControl mt="2%" isInvalid={errors?.name} size='lg' fontSize={"lg"}>
                <FormLabel htmlFor="name" fontWeight={'normal'}>
                  Nom *
                </FormLabel>
                <Stack spacing={4}>
                  <InputGroup >
                    <InputLeftAddon
                      bg={"brand.primary.100"}
                      color={"white"}
                    >https://</InputLeftAddon>
                    <Input id="email" type="name" {...register("name")} name="name" placeholder="Entrer votre nom " />
                    {/* <InputRightAddon>.com</InputRightAddon> */}
                  </InputGroup>
                </Stack>
                <FormHelperText>Votre nom de domaine est obligatoire</FormHelperText>
                <FormErrorMessage>
                  {errors?.name && errors?.name?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt="2%" isInvalid={errors?.backend} size='lg' fontSize={"lg"}>
                <FormLabel htmlFor="name" fontWeight={'normal'}>
                  Seveur backend*
                </FormLabel>
                <Stack spacing={4}>
                  <InputGroup >
                    <InputLeftAddon
                     bg={"brand.primary.100"}
                     color={"white"}
                    >https://</InputLeftAddon>
                    <Input id="backend" type="backend" {...register("backend")} name="backend" placeholder="Entrer votre serveur backend " />
                    <InputRightAddon>.com</InputRightAddon>
                  </InputGroup>
                </Stack>
                <FormHelperText>Votre serveur backend est obligatoire</FormHelperText>
                <FormErrorMessage>
                  {errors?.backend && errors?.backend?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt="2%" isInvalid={errors?.mail} size='lg' fontSize={"lg"}>
                <FormLabel htmlFor="email" fontWeight={'normal'}>
                  Email*
                </FormLabel>
                <Stack spacing={4}>
                  <InputGroup >
                    {/* <InputLeftAddon >https://</InputLeftAddon> */}
                    <Input id="email" type="email" {...register("mail")} name="mail" placeholder="Entrer votre email" />
                    {/* <InputRightAddon
                    
                    bg={"brand.primary.100"}
                    color={"white"}
                    >{watch("name")? `@${watch("name")}`: "@domaine"}</InputRightAddon> */}
                  </InputGroup>
                </Stack>
                <FormHelperText>Veuillez fournir une adresse email valide.</FormHelperText>
                <FormErrorMessage>
                  {errors?.mail && errors?.mail?.message}
                </FormErrorMessage>
              </FormControl>

            </>
          }
          {token === "code" &&
            <>
              <FormControl mt="2%" isInvalid={errors?.code} size='lg' fontSize={"lg"}>
                <FormLabel htmlFor="code" fontWeight={'normal'}>
                  Code OTP*
                </FormLabel>
                <Controller
                  control={control}
                  name={"code"}
                  defaultValue={""}
                  render={({ field: { onChange, value, ref } }) => (

                    <HStack
                      as={"Flex"}
                      justify={"space-between"}
                    >
                      <PinInput
                        onChange={onChange}
                        value={value}
                        focusBorderColor="brand.primary.100"
                        size={"lg"}
                      >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                  )}
                />
                <FormHelperText>Entrer le code de validation</FormHelperText>
                <FormErrorMessage>
                  {errors?.code && errors?.code?.message}
                </FormErrorMessage>
              </FormControl>

            </>
          }

          {"validation" === token &&
            <> <FormControl mt="2%"  size='lg'>
              <FormLabel htmlFor="password" fontWeight={'normal'}>
                Secret
              </FormLabel>
              <Textarea id="motpasse" 
              defaultValue={`SECRET_CRT=${encode(response?.secret.toString())}`}
              type="text" {...register("motpasse")} name="motpasse" placeholder="Enter the password" />

            </FormControl>
              <FormControl mt="2%" size='lg'>
                <FormLabel htmlFor="password" fontWeight={'normal'}>
                 Certificat
                </FormLabel>
                <Textarea   type="text" 
                defaultValue={`CRT=${encode(JSON.stringify(response?.response))}`}
                {...register("confirm_password")} name="confirm_password" placeholder="Enter the password" />

              </FormControl>
            </>
          }
          {"qrcode" === token &&
            <Button
              w="full"
              bgColor={"whiteAlpha.900"}
              color="brand.primary.100"
              variant="solid"
              h="auto"
            ><QRCode
                ecLevel="Q"
                logoImage={logo}
                value={qrcode}
                size={384}
                removeQrCodeBehindLogo={true}
              /></Button>
          }

          {["registration", "code", ].includes(token) && <ButtonGroup mt="5%" w="100%">
            <Flex w="100%" justifyContent="space-between">
              <Spacer />
              {<Button
                w="full"
                color={"whiteAlpha.900"}
                bgGradient="linear(to-l,brand.secondary, brand.primary.100)"
                variant="solid"
                onClick={handleSubmit(onSubmit)}
                size={"lg"}
              >
                {token === "code" ? "Valider le code" : token === 'registration' ? "Création de votre compte" : "Valider le mot de passe"}
              </Button>

              }
              <Box></Box>
            </Flex>
          </ButtonGroup>}

        </form>
      </Stack>
      <HStack>
        <Divider />
        <IconButton variant="tertiary" icon={<UilEllipsisH />} aria-label="Add" />
        <Divider />
      </HStack>
      <Box textAlign={"center"} pb={5}>


      </Box>
    </Stack>
  )
}

export default RegistrationForm