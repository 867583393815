import { Icon } from '@chakra-ui/icons'
import { Divider, Flex, Input, InputGroup, HStack, InputLeftElement, Stack } from '@chakra-ui/react'
import * as React from 'react'
import {
  FiBarChart2,
  FiBookmark,
  FiCheckSquare,
  FiHelpCircle,
  FiHome,
  FiSearch,
  FiSettings,

  FiUsers,
} from 'react-icons/fi'
import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import LogoSA from './LogoSA'
import { NavButton } from './NavButton'
import { UserProfile } from './UserProfile'
import { Link } from "react-router-dom";
export const Sidebar = () => (
  <Flex as="section" minH="100vh" bg="bg-canvas"
  >
    <Flex
      flex="1"
      bg="brand.secondary"

      // bgGradient={'linear(to-r, brand.secondary), brand.primary.100'}
      color="white"
      maxW={{
        base: '100%',
        sm: 'xs',
      }}
      py={{
        base: '6',
        sm: '8',
      }}

    >
      <Stack justify="space-between" spacing="1">
        <Stack
          spacing={{
            base: '5',
            sm: '6',
          }}
          shouldWrapChildren
        >
          {/* <Logo /> */}
          <HStack>
            <LogoSA />
            <ColorModeSwitcher />
          </HStack>
          {/* <InputGroup size={{base:"sm",  md:"md"}}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color="on-accent" boxSize="5" />
            </InputLeftElement>
            <Input placeholder="Search" variant="filled" colorScheme="teal" />
          </InputGroup> */}
          <Stack spacing="1">
            <Link to="/">
              <NavButton label="Accueil" icon={FiHome} />
            </Link>
            <Link to="/">
              <NavButton label="Artistes" icon={FiBarChart2} aria-current="page" />
            </Link>
            <Link to="/">
              <NavButton label="Projets" icon={FiCheckSquare} />
            </Link>

          </Stack>
        </Stack>
        <Stack
          spacing={{
            base: '5',
            sm: '6',
          }}
        >
          <Stack spacing="1">
            <Link to="/login">
              <NavButton label="Login" icon={FiUsers} />
            </Link>

          </Stack>
          <Divider />
          {/* <UserProfile
            name="Christoph Winston"
            image="https://tinyurl.com/yhkm2ek8"
            email="chris@yoopmail.com"
          /> */}
        </Stack>
      </Stack>
    </Flex>
  </Flex>
)
