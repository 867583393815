import { Box, Container, Text, Stack, Button, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'

import { Link } from "react-router-dom";
import FooterSimple from '../Footer';
import Navbar from '../Header';
import { Banner } from './banner';
import { SimpleTable } from '../SimpleTable/table';
import { SimpleTableNS } from '../SimpleTable';
import { useDisclosure } from '@chakra-ui/react';
import { FormOrganisation } from '../Organisation';
import { GlobalContext } from '../Context';
import { ListeADonateur } from '../donateur/liste';
import { Description } from '../association/description';
import { ListeAssociation } from '../association/liste';
import { useNavigate } from 'react-router-dom';
import { Apropos } from './Apropos';

const Home = () => {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const navigate = useNavigate()
  const { dataUser, logout, service, token } = React.useContext(GlobalContext)
  const [item, setItem] = React.useState({});
  const [financements, setFinancements] = React.useState([]);
  const [organisations, setOrganisations] = React.useState([]);
  const onGet = () => {
    service.get(token, "organisation/me", "", (resp) => {
      // alert(JSON.stringify(resp?.data?.data))
      setItem(resp?.data)
      // alert(JSON.stringify(resp?.data?.data))

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }

      }
    )
  }
  React.useEffect(() => {
    service.create(token, "filterfinancement", {
      secteurs: [],
      donateurs: [],
      pays: []

    }, (resp) => {
      setFinancements(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )
    service.create(token, "filterorganisation", {
      secteurs: [],
      donateurs: [],
      pays: []

    }, (resp) => {
      setOrganisations(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )


  }, []);

  return (


    < >
      <Banner />
      <Container
        // pt={{
        //   base: '8',
        //   lg: '6',
        // }}
        
        pb={{
          base: '5',
          lg: '5',
        }}
        minW={{
          base: '100%',
          md: '90%',
        }}
        bgGradient='linear(to-tl,brand.jaune.900, brand.primary.100)'
      >
        <Apropos />
        {/* <Stack
          spacing="4"
          direction={{
            base: 'column',
            lg: 'row',
          }}
          justify="space-between"
        >
          <Box>
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                lg: 'column',
              }}
            >

              <Text fontSize="lg" fontWeight="bold" mt="3" color={"white"}
              
              display={{ base: "none", md: "block" }}>
                Je suis une Organisation de la Société Civile
              </Text>
              {!dataUser?.user?.organisation_id ? <Link to="/registration">
                <Button
                  size={{ base: "md", md: "md" }}
                  w="full"
                  leftIcon={<FiSend />}
                  onClick={onOpen}
                  textTransform="uppercase"
                  bg="brand.jaune.900" color={"white"}>J'inscris mon organisation
                </Button>
              </Link> :
                <Link to="/organisation">
                  <Button
                    size={{ base: "full", md: "md" }}
                    w="full"
                    leftIcon={<FiSend />}
                    onClick={onOpen}
                    textTransform="uppercase"
                    bg="brand.jaune.900" color={"white"}>Mon organisation
                  </Button>
                </Link>
              }
            </Stack>
          </Box>

          <Box>
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                lg: 'column',
              }}
              align="center"
            >
              <Text fontSize="lg" fontWeight="bold" mt="3" color={"white"}
              display={{ base: "none", md: "block" }}
              >
                Je suis un bailleur de fonds
              </Text>
              <Link to="/donateur">
                <Button
                  size={{ base: "md", md: "md" }}
                  w="full"
                  leftIcon={<FiSend />}
                  textTransform="uppercase"
                  bg="brand.bleu.900" color={"white"}>Je soumets une opportunité de financement</Button>
              </Link>
            </Stack>
          </Box>
        </Stack> */}

      </Container>
      {/* <ListeADonateur title={"Opportunités de financement existantes"} financements={financements} />
      <Description organisations={organisations} />
    

      <FormOrganisation
        isOpen={isOpen}
        onClose={onClose}
        item={{ administrateur_id: dataUser?.user?.id }}

      /> */}


    </>

  )
}

export default Home