import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from '@chakra-ui/react'
export const AccordionMotion = ({ i, expanded, setExpanded, title, content }) => {
  const isOpen = i === expanded;

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
      <AccordionItem>
        <h2>
          <AccordionButton 
          bgGradient= {isOpen? 'linear(to-r,brand.secondary, brand.primary.100)':'linear(to-r, brand.primary.100, brand.secondary)' }
          onClick={() => setExpanded(isOpen ? false : i)}
          color={"white"}
          >
            <motion.header
              initial={false}
              // animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
              
            >
              <Box as='span' flex='1' textAlign='left' 
              w="full"
              fontWeight="extrabold"
              //  letterSpacing="tight"
              >

                {title}

              </Box>
              <AccordionIcon />
            </motion.header>

          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} color={"white"}>
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {content}
              </motion.section>
            )}
          </AnimatePresence>
        </AccordionPanel>
      </AccordionItem>

  );
};