import { Box, Link,Flex, Stack, Button, IconButton, Center, Heading, LightMode, SimpleGrid, Text, Spacer } from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'
import bg1 from './../../Media/images/bannn.png'
import { ReactTyped } from "react-typed";

const TypedText = () => {
  return (
    <div>
      <Heading  size={{base: "sm", md: "lg"}} fontWeight="extrabold"
          // bgClip='text'
          fontSize={{ base: "20px", md: "48px" }}
          color={"whiteAlpha.900"}
           >
         {" "}
        <ReactTyped
          strings={["Structure d'accompagnement", "Structure de Développement artistique."]}
          typeSpeed={100}
          loop
          backSpeed={20}
          cursorChar=">"
          showCursor={true}
        />
      </Heading>
    </div>
  );
};

export const Banner = () => {
  return (
    <Box
      as="section"
      bg="gray.800"
      py="12"
      position="relative"
      minH={{ base: '560px', md: '640px' }}
      bgImage={bg1}
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '8xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" h="full">
          <Heading  
          // size={{base: "md", md: "lg"}} 
          // bgClip='text'
          fontWeight="extrabold"
          fontSize={{ base: "34px", md: "75px" }}
          // bgClip='text'
          // bgGradient='linear(to-r,brand.jaune.900, brand.primary.100)'
          // bgGradient='linear(to-r,brand.secondary, brand.primary.100)'
          color={"brand.primary.100"}
           >
          Miti Nga Incubator
          </Heading>
          <TypedText 
          
          />

        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.500"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
        <Flex w="100%" justifyContent="space-between">
            <Box textAlign="center" color="white">
              <Text>Artistes</Text>
              <Text fontSize="3xl">10</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Projets</Text>
              <Text fontSize="3xl">4</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Sponsors</Text>
              <Text fontSize="3xl">3</Text>
            </Box>
            
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}