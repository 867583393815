import {
  Box,
  Button,
  Heading,
  Img,
  Stack,
  Text,
  Accordion,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'
import { HiPlay } from 'react-icons/hi'
import { Link } from "react-router-dom";
import bg2 from './../../Media/images/bg2.png'
import {  AccordionMotion } from './Accordion';
export function Apropos() {
  const [expanded, setExpanded] = React.useState(0);
  const activites = [{
    id: 0,
    title: "Mentorat personnalisé",
    content: " Chaque artiste est jumelé avec un mentor expérimenté dans son domaine, qui offre des conseils et un soutien individualisé pour aider à perfectionner les compétences artistiques et professionnelles.",
  },
  {
    id: 1,
    title: "Résidences artistiques",
    content: " Miti Nga propose des programmes de résidence où les artistes peuvent se concentrer sur leur travail dans un environnement inspirant et collaboratif, souvent avec accès à des ressources techniques et logistiques.",
  },
  {
    id: 3,
    title: "Formation et ateliers ",
    content: "Les sessions de formation sont organisées pour renforcer les compétences pratiques et théoriques des artistes, couvrant des sujets tels que la gestion de carrière, la promotion artistique et la gestion financière.",
  },
  {
    id: 4,
    title: "Réseautage et collaborations ",
    content: " Miti Nga facilite les opportunités de réseautage avec d'autres artistes, professionnels de l'industrie et institutions culturelles, encourageant les collaborations et les partenariats stratégiques.",
  },
  {
    id: 5,
    title: "Promotion et visibilité",
    content: "La structure aide à promouvoir le travail des artistes incubés à travers divers canaux médiatiques et plateformes en ligne, augmentant ainsi leur visibilité et leur accès à de nouveaux publics.",
  },
  ]
  return (
    <Box as="section" pb="10">
      <Box maxW={{ base: 'xl', md: 'full' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '3rem', lg: '2rem' }}
          mt="8"
          align={{ lg: 'center' }}
          justify="space-between"
        >
          <Box flex="1" maxW={{ lg: '55%' }}>
            <Heading
              as="h1"
              size={{ base: "md", md: "lg" }}
              color={"brand.secondary"}
              mt="8"
              fontWeight="extrabold"
              letterSpacing="tight"
              textAlign='center'
            >
              Miti Nga Incubator
            </Heading>
            <Text color={mode('white', 'white')} mt="4" fontSize={{ base: "md", md: "xl" }} fontWeight="medium">
              {/* Cette plateforme digitale vise à faciliter la mise en relation des organisations de la société civile et des partenaires techniques et financiers et à recenser les opportunités de financements pour les organisations de la société civile au Sahel.  */}
              {`"Miti Nga" se distingue comme un incubateur dédié au soutien et à l'essor des talents artistiques émergents. Fondé sur une passion commune pour l'art sous toutes ses formes, Miti Nga offre une plateforme dynamique où les artistes peuvent non seulement développer leurs compétences créatives, mais aussi explorer et affirmer leur identité artistique. Conçu comme un sanctuaire pour la créativité, cet incubateur se consacre à fournir aux artistes les outils, les ressources et le soutien nécessaires pour transformer leurs visions en œuvres concrètes et significatives. Que ce soit dans les arts visuels, la musique, le théâtre, la littérature ou d'autres formes d'expression, Miti Nga se positionne comme un catalyseur essentiel, favorisant l'épanouissement artistique et la découverte de nouveaux horizons artistiques.


              La structure d'accompagnement de Miti Nga est conçue pour répondre aux besoins spécifiques des artistes à différentes étapes de leur parcours. Elle comprend plusieurs volets essentiels :
`}

            </Text>
            <Accordion
        
            >
              {activites.map((item) => (
                <AccordionMotion i={item?.id} expanded={expanded} setExpanded={setExpanded} {...item} />
              ))}
            </Accordion>
          </Box>
          <Box pos="relative" w={{ base: 'full', lg: '40%' }} h={{ base: 'auto', lg: '100%' }}>
            <Img
              w="full"
              pos="relative"
              zIndex="1"
              h={{ lg: 'full' }}
              objectFit="cover"
              src={bg2}
              alt="Screening talent"
            />
            <Box
              pos="absolute"
              w="100%"
              h="100%"
              top="-6"
              left="-4"
              bgGradient='linear(to-tl,brand.jaune.900, brand.bleu.900)'
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}