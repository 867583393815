import React from 'react'
import {
    Box,
    HStack,
    Center,
    Heading,
    Image,
    Link
} from '@chakra-ui/react'
import logo from "../../Media/images/logo.png"
import { useNavigate } from 'react-router-dom';
function LogoSA({
    color,
    bg,
    ...props
}) {
    return (

            <Box
                borderColor="white"
                // shadow='md'
                color="white"
                as="a"
                to={"/"}
                // borderRadius={{ base: "sm", md: "sm" }}
                // p={5}
                {...props}

            >
                <Image
                    src={logo}
                    alt={"Sangue Logo"}
                    width="auto"
                    h={{ base: "64px", md: "64px" }}
                    objectFit="cover"
                    transition="all 0.2s"
                    _groupHover={{ transform: 'scale(1.05)' }}
                />
            </Box>
    )
}
export function LogoSAVert({
    color,
    bg,
    title,
    ...props
}) {
    let navigate = useNavigate ();
    return (
        
        <Box
            borderColor="brand.primary.100"
            shadow='lg'
            color="white"
            bgGradient="linear(to-l,brand.secondary, brand.primary.100)"
            // roundedLeft="xl"
            // roundedRight="xl"
            // py={2}
            w='100%'
            // as="section"
            justify="space-between"
            // mb={5}
            as={Link}
           onClick={()=>navigate('/')}
        >
            <Center>
                <HStack w='100%' justify={"space-between"}>
                    {/* <Box></Box> */}
                    <Link to="/">
                    <LogoSA px={'auto'}/>
                    </Link>
                    <Box
                        color="white"
                        // color="brand.primary.100"
                        as='button' borderRadius='md'
                        px={2}
                        textAlign="center"
                        w="0.8rm"
                    >

                        <Heading fontWeight={"bold"} size='md' fontSize='34px' color={"white"} >
                            {title}
                        </Heading>

                    </Box>
                    <Box></Box>
                </HStack>
            </Center>
        </Box>
    )
}

export default LogoSA